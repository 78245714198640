
import { getInterestsList } from '@/api/interests';
import { getAgencyList } from '@/api/user';
import { getRoleList } from '@/api/role';
import { IAgency, IInterests, IRole } from '@/types';
import { Vue, Component, Watch } from 'vue-property-decorator';
import MyBlog from '@/components/blog/index.vue';
import Slick from 'vue-slick';

declare const window: any;
declare const kakao: any;

@Component({
  name: 'MapIndex',
  components: {
    MyBlog,
    Slick,
  },
})
export default class extends Vue {
  mounted() {
    this.getRoleList();
    /* eslint-disable */
    window.kakao && window.kakao.maps ? this.initMap() : this.addScript();
    /* eslint-enable */
    /*
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.handleCurrentPosition as any);
    }
    */
  }

  @Watch('markerList')
  private handleChangeMarkerList(markerList: any[]) {
    this.clusterer.addMarkers(markerList);
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private listQuery = {
    address: '경남',
    roleCode: '',
    interest: '',
    searchValue: '',
  }

  private currentLocation: any = {
    lat: '',
    lon: '',
  };

  private roleList: IRole[] = [];

  private interestList: IInterests[] = [];

  private agencyList: any[] = [];

  private selectedAgency: IAgency | null = null;

  private clusterer: any | null = null;

  private geocoder: any = {};

  private markerList: any[] = [];

  private blogVisible = false;

  /* eslint-disable */

  private makerspaceImage = require('@/assets/img/map/map_type01.png');

  private startupImage = require('@/assets/img/map/map_type02.png');

  private supportImage = require('@/assets/img/map/map_type03.png');

  private investImage = require('@/assets/img/map/map_type04.png');

  /* eslint-enable */

  private agencySlickOptions = {
    autoplay: false,
    arrows: true,
    dots: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: true,
    mobileFirst: true,
    infinite: true,
    focusOnSelect: true,
    pauseOnHover: true,
    prevArrow: ('.agency-prev'),
    nextArrow: ('.agency-next'),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  private handleClickSlick(refName: string, type: string) {
    if (this.$refs[refName]) {
      const reference: any = this.$refs[refName];
      if (type === 'prev') reference.prev();
      if (type === 'next') reference.next();
    }
  }

  private getMenuList() {
    const load = this.$loading.show();
    getAgencyList(this.listQuery).then((res) => {
      this.agencyList = res.data;
      this.init();
      this.$nextTick(() => {
        load.hide();
      });
    }).catch(() => {
      load.hide();
    });
  }

  private getAgencyList() {
    getAgencyList(this.listQuery).then((res) => {
      this.agencyList = res.data;
      this.init();
    });
  }

  private getRoleList() {
    getRoleList().then((res) => {
      this.roleList = res.data;
    });
  }

  private handleChangeRoleCode() {
    this.listQuery.interest = '';
    getInterestsList(this.listQuery.roleCode).then((res) => {
      this.interestList = res.data;
    });
    this.handleChangeListQuery();
  }

  /* eslint-disable */
  /*
  private handleCurrentPosition(geoLocation: any) {
    this.currentLocation = {
      lat: geoLocation.coords.latitude,
      lon: geoLocation.coords.longitude,
    };
  }
  */
  /* eslint-enable */

  private sortAgencyByDistance() {
    this.agencyList.sort((a: any, b: any) => {
      if (a.distance > b.distance) return 1;
      if (a.distance < b.distance) return -1;
      return 0;
    });
  }

  private initMap() {
    /* eslint-disable */
    // const lat = this.currentLocation.lat;
    // const lon = this.currentLocation.lon;
    const container = document.getElementById('map');
    const options = { center: new kakao.maps.LatLng(35.23845697482418, 128.69233362490976), level: 3 };
    window.map = new kakao.maps.Map(container, options);
    this.geocoder = new kakao.maps.services.Geocoder();
    this.clusterer = new kakao.maps.MarkerClusterer({
      map: window.map, // 마커들을 클러스터로 관리하고 표시할 지도 객체
      averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
      minLevel: 4 // 클러스터 할 최소 지도 레벨
    });
    this.getAgencyList();
    /* eslint-enable */
  }

  private getTypeNum(roles: string[]) {
    return this.roleList.findIndex((r) => r.roleCode === roles[0]);
  }

  private getTypeName(roles: string[]) {
    switch (this.roleList.findIndex((r) => r.roleCode === roles[0])) {
      case 0:
        return '메이커스페이스';
      case 1:
        return '스타트업';
      case 2:
        return '지원기관';
      case 3:
        return '투자기관';
      default:
        return '';
    }
  }

  private addScript() {
    /* eslint-disable */
    const script = document.createElement('script');
    script.onload = () => kakao.maps.load(this.initMap);
    script.src = `${window.location.protocol}//dapi.kakao.com/v2/maps/sdk.js?libraries=services,clusterer&autoload=false&appkey=${process.env.VUE_APP_KAKAO_API_KEY}`;
    document.head.appendChild(script);
    /* eslint-enable */
  }

  private isBlogUser(selectedAgency: IAgency) {
    const roleIndex = this.getTypeNum(selectedAgency.roles);
    if (roleIndex > -1) {
      return this.roleList[roleIndex].myBlogState;
    }
    return false;
  }

  private handleClickAgency(agency: IAgency, isMap: boolean) {
    this.selectedAgency = agency;
    /* eslint-disable */
    if (!isMap) {
      this.geocoder.addressSearch(agency.address, function(result: any, status: any) {
        if (status === kakao.maps.services.Status.OK) {
          const markerPosition = new kakao.maps.LatLng(Number(result[0].y), Number(result[0].x));
          window.map.setLevel(8);
          window.map.panTo(markerPosition);
          setTimeout(() => {
            window.map.setLevel(3);
          }, 5000);
        }
      });
    }
    /* eslint-enable */
  }

  private async createMarkers() {
    /* eslint-disable */
    this.markerList = [];
    this.clusterer.clear();
    const that = this;
    this.agencyList.forEach((item: any, index: number) => {
      const imageSize = new kakao.maps.Size(55, 60);
      const imageOption = {offset: new kakao.maps.Point(27, 69)};

      const roleIndex = this.getTypeNum(item.roles);
      let imageSrc = '';
      if (roleIndex === 0) imageSrc = this.makerspaceImage;
      if (roleIndex === 1) imageSrc = this.startupImage;
      if (roleIndex === 2) imageSrc = this.supportImage;
      if (roleIndex === 3) imageSrc = this.investImage;

      const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
      this.geocoder.addressSearch(item.address, function(result: any, status: any) {
        if (status === kakao.maps.services.Status.OK) {
          item.distance = 999;
          /*
          if (that.currentLocation.lat && that.currentLocation.lon) {
            if ((Number(result[0].y) === that.currentLocation.lat) && (Number(result[0].x) === that.currentLocation.lon)) {
              item.distance = 0;
            } else {
              const radlat1 = Math.PI * that.currentLocation.lat / 180;
              const radlat2 = Math.PI * that.currentLocation.lon / 180;
              const theta = that.currentLocation.lon - Number(result[0].x);
              const radtheta = Math.PI * theta / 180;
              let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
              if (dist > 1) dist = 1;
              dist = Math.acos(dist);
              dist = dist * 180 / Math.PI;
              dist = dist * 60 * 1.1515;
              dist = dist * 1.609344 //킬로미터 계산
              item.distance = dist;
            }
          }
          */
          const markerPosition = new kakao.maps.LatLng(Number(result[0].y), Number(result[0].x));
          // if (index === 0) window.map.panTo(markerPosition);
          const marker = new kakao.maps.Marker({
            position: markerPosition,
            image: markerImage,
          });
          kakao.maps.event.addListener(marker, 'click', function() {
            that.handleClickAgency(item, true);
          });
          that.markerList.push(marker);
        }
        if (index + 1 === that.agencyList.length) {
          setTimeout(() => {
            that.sortAgencyByDistance();
          }, 500);
        };
      });
      /* eslint-enable */
    });
  }

  private handleClickSearch() {
    window.map.setLevel(8);
    this.getAgencyList();
  }

  private handleClickBlog() {
    this.blogVisible = !this.blogVisible;
  }

  private handleClickType(index: number) {
    if (this.roleList.length > index && this.listQuery.roleCode !== this.roleList[index].roleCode) {
      this.listQuery.roleCode = this.roleList[index].roleCode;
      this.listQuery.interest = '';
      this.getAgencyList();
      window.map.setLevel(8);
    }
  }

  private getHomepageUrl(url: string) {
    if (url.startsWith('http') || url.startsWith('https')) return url;
    return `http://${url}`;
  }

  private async init() {
    await this.createMarkers();
  }

  private handleChangeListQuery() {
    setTimeout(() => {
      this.handleClickSearch();
    }, 100);
  }

  private scrollToLeft() {
    const organBoxList = document.querySelector('.organ_box_list') as HTMLElement;
    if (organBoxList) {
      organBoxList.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  private scrollToRight() {
    const organBoxList = document.querySelector('.organ_box_list') as HTMLElement;
    if (organBoxList) {
      organBoxList.scrollTo({
        left: organBoxList.scrollWidth,
        behavior: 'smooth',
      });
    }
  }
}
